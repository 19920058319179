import React from 'react';
import { appService } from '../../services/appService';

const Footer = () => {
  const { clientSettings } = appService.getTenantData();

  if (!clientSettings.footerLogo) {
    return <></>;
  }

  return (
    <footer className="footer footer_center_image">
      <img src={clientSettings.footerLogo} alt="Footer Logo" />
    </footer>
  );
};

export default Footer;
export { Footer };
